const _ = window._;

import {capitalizeFirst} from 'js/studios/shared/utils/strings';

/**
 * This method redirects to login if it detects a forbidden error with the correct format (contains 'needsLogin')
 */
export function parseForbidden(xhr){
  if (xhr.status == 403 && xhr.responseJSON && xhr.responseJSON['needsLogin']){
    window.location.href = window.cm.urls['login'] + '?next=' + window.location.href;  // redirect keeping current url in tact. Note it keeps any get params as well
    throw new Error("Ending process and redirecting to login");
  }
}

export function parseResponseError(response){
  if (typeof response === "object"){
    if (response.status === 400){
      if (typeof response.responseJSON === "object"){
        return capitalizeFirst(_.values(response.responseJSON)[0][0]);
      }
      return response.responseJSON;
    } else if (response.status === 413){
      return "File is too large";
    } else if (response.status === 403){
      if (response.responseText.toLowerCase().search('csrf') > -1){
        return "Please refresh the page and try again. If the problem persists, contact support.";
      } else {
        return "Forbidden. You may need to login again";
      }
    } else if (response.status === 404){
      return "Not found. You may need to refresh the page to see the latest information."
    } else if (response.status === 409){
      return "Conflict: " + response.responseText;
    } else if (response.status >= 500 && response.status < 600){
      return "Issues with our servers";
    } else {
      return capitalizeFirst(response.statusText);
    }
  } else {
    return response;
  }
}
